import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import axios from "axios";

import {Button} from "@gravity-ui/uikit";

import {getJSONAcceptAuthConfig, getJSONConfig, PUBLIC_PROJECTS_ENDPOINT, WORKSHOPS_INFO_ENDPOINT} from "../../api/api";
import ProjectCard from "../../components/projectCard/ProjectCard";
import {Accordion} from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";

const ProjectsPage = ({ archive = false }) => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);

    const [uniqueWorkshops, setUniqueWorkshops] = useState([]);
    const [uniqueVenues, setUniqueVenues] = useState([]);
    const [uniqueStreams, setUniqueStreams] = useState([]);
    const [uniqueKeywords, setUniqueKeywords] = useState([]);

    const [selectedWorkshops, setSelectedWorkshops] = useState([]);
    const [selectedVenues, setSelectedVenues] = useState([]);
    const [selectedStreams, setSelectedStreams] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);

    const [addedProjects, setAddedProjects] = useState(() => (JSON.parse(localStorage.getItem('addedProjects')) || []));
    const [activeWorkshop, setActiveWorkshop] = useState(null);

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'addedProjects') {
                const updatedAddedProjects = JSON.parse(event.newValue) || [];
                setAddedProjects(updatedAddedProjects);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    // useEffect(() => {
    //     const addedProjects = JSON.parse(localStorage.getItem('addedProjects')) || [];
    //     setAddedProjects(addedProjects);
    // }, []);

    const handleAddToApplication = (project) => {
        let updatedAddedProjects;
        const projectId = project.id;
        if (addedProjects.find(p => p.id === projectId)) {
            updatedAddedProjects = addedProjects.filter(p => p.id !== projectId);
        } else {
            updatedAddedProjects = [...addedProjects, project];
        }
        localStorage.setItem('addedProjects', JSON.stringify(updatedAddedProjects));
        setAddedProjects(updatedAddedProjects);
    };

    useEffect(() => {
        Promise.all([
            axios
            .get(PUBLIC_PROJECTS_ENDPOINT(archive ? 'False' : 'True'), getJSONConfig())
            .then(response => {
                setProjects(response.data);
                setLoading(false);

                // Получаем уникальные названия воркшопов
                const workshops = Array.from(new Set(response.data.map(project => project.workshop.name))).filter(Boolean).sort();
                setUniqueWorkshops(workshops);

                // Получаем уникальные названия площадок
                const venues = Array.from(new Set(response.data.map(project => project.venue?.name))).filter(Boolean).sort();
                setUniqueVenues(venues);

                // Получаем уникальные названия потоков
                const streams = Array.from(new Set(response.data.map(project => project.local_stream))).filter(Boolean).sort();
                setUniqueStreams(streams);

                // Получаем уникальные ключевые слова
                let keywords = [];
                response.data.forEach(project => {
                    keywords = keywords.concat(project.keywords);
                });
                keywords = Array.from(new Set(keywords)).filter(Boolean).sort();
                setUniqueKeywords(keywords);
            })
            .catch(error => {
                console.error('Error getting projects:', error);
                setLoading(false);
            }),
            axios
            .get(WORKSHOPS_INFO_ENDPOINT, {
                params: {is_active: "True"},
                headers: getJSONAcceptAuthConfig().headers,
            })
            .then(response => {
                setActiveWorkshop((response.data[0]))
            })
            .catch(error => {
                console.error('Error getting active workshop data:', error);
            }),
        ])
        ;
    }, []);

    const filterProjects = () => {
        return projects.filter(project => {

            // Фильтрация по воркшопу
            if (selectedWorkshops.length > 0 && !selectedWorkshops.includes(project.workshop.name)) {
                return false;
            }

            // Фильтрация по площадке
            if (selectedVenues.length > 0 && !selectedVenues.includes(project.venue?.name)) {
                return false;
            }

            // Фильтрация по потоку
            if (selectedStreams.length > 0) {
                if (!project.local_stream || !selectedStreams.includes(project.local_stream)) {
                    return false;
                }
            }

            // Фильтрация по ключевым словам
            if (selectedKeywords.length > 0 && !selectedKeywords.some(keyword => project.keywords.includes(keyword))) {
                return false;
            }

            return true;
        });
    };

    const handleChange = (name, value, checked, setData) => {
        setData(prevData => {
            if (checked) {
                return [...prevData, value];
            } else {
                return prevData.filter(data => data !== value);
            }
        });
    };

    const handleCheckboxChange = event => {
        const {name, value, checked} = event.target;
        switch (name) {
            case 'selectedWorkshops':
                handleChange(name, value, checked, setSelectedWorkshops);
                break;
            case 'selectedVenues':
                handleChange(name, value, checked, setSelectedVenues);
                break;
            case 'selectedStreams':
                handleChange(name, value, checked, setSelectedStreams);
                break;
            case 'selectedKeywords':
                handleChange(name, value, checked, setSelectedKeywords);
                break;
            default:
                break;
        }
    };

    const handleCheckboxAllChange = event => {
        const {name, checked} = event.target;
        switch (name) {
            case 'selectedWorkshops':
                setSelectedWorkshops(checked ? uniqueWorkshops : []);
                break;
            case 'selectedVenues':
                setSelectedVenues(checked ? uniqueVenues : []);
                break;
            case 'selectedStreams':
                setSelectedStreams(checked ? uniqueStreams : []);
                break;
            case 'selectedKeywords':
                setSelectedKeywords(checked ? uniqueKeywords : []);
                break;
            default:
                break;
        }
    };

    const resetFilters = () => {
        setSelectedWorkshops([]);
        setSelectedVenues([]);
        setSelectedStreams([]);
        setSelectedKeywords([]);
    };

    const createFilterItem = (title, data, name, selectedData, scrollable = false) => (
        <Accordion.Item key={title} eventKey={title}>
            <Accordion.Header className="public-project-filter__name">{title}</Accordion.Header>
            <Accordion.Body className={scrollable ? 'accordion-body_scroll' : ''}>
                <div>
                    <div className="form-check">
                        <input
                            className={`form-check-input ${0 < selectedData.length && selectedData.length < data.length ? `${name}-indeterminate` : ''}`}
                            type="checkbox"
                            name={name}
                            checked={selectedData.length === data.length}
                            value={"Все"}
                            id={`${name}CheckboxAll`}
                            onChange={handleCheckboxAllChange}/>
                        <label className="form-check-label" htmlFor={`${name}CheckboxAll`}>
                            Все
                        </label>
                    </div>
                </div>
                <div className={scrollable ? 'accordion-div_scroll' : ''}>
                    {data.map((item, index) => (
                        <div key={index} className="form-check">
                            <input className="form-check-input" type="checkbox"
                                   name={name}
                                   checked={selectedData.includes(item)}
                                   value={item}
                                   id={`${name}Checkbox${index}`}
                                   onChange={handleCheckboxChange}/>
                            <label className="form-check-label" htmlFor={`${name}Checkbox${index}`}>
                                {item}
                            </label>
                        </div>
                    ))}
                </div>
            </Accordion.Body>
        </Accordion.Item>
    );

    const AccordionFilter = (className = "") => (
        <Accordion defaultActiveKey={['Мастерская', 'Площадка', 'Поток', 'Ключевые слова']}
                   className={`public-project-filter bmm-text bmm-text_align-start first-column ${className}`}
                   alwaysOpen>
            {archive && createFilterItem("Мастерская", uniqueWorkshops, "selectedWorkshops", selectedWorkshops)}
            {createFilterItem("Площадка", uniqueVenues, "selectedVenues", selectedVenues)}
            {createFilterItem("Поток", uniqueStreams, "selectedStreams", selectedStreams)}
            {createFilterItem("Ключевые слова", uniqueKeywords, "selectedKeywords", selectedKeywords, true)}
            <div className={"accordion-item public-project-filter__reset-button"}>
                <Button view={"normal"} size={"xl"} onClick={resetFilters}>
                    Сбросить
                </Button>
            </div>
        </Accordion>
    );

    const filteredProjects = filterProjects();

    return (
        <>
            <div className="page-title">{archive ? 'Проекты прошедших мастерских' : 'Проекты Мастерской'}</div>

            {loading ? (
                    <LoadingSpinner/>
                ) : (
                    projects.length > 0 ? (
                        <>
                            <div className="flex-container">
                                {AccordionFilter("public-project-filter_desktop")}
                                <Accordion
                                    className={"public-project-filter public-project-filter_mobile bmm-text first-column"}
                                    alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header
                                            className={"public-project-filter__name"}>Фильтры</Accordion.Header>
                                        <Accordion.Body bsPrefix={"public-project-filter_mobile-accordion-body"}>
                                            {AccordionFilter()}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <div className={"second-column"}>
                                    {filteredProjects.length === 0 ? (
                                        <p className={"bmm-text mt-4"}>
                                            Проектов, соответствующих вашим критериям поиска, не
                                            найдено. <br/> Попробуйте изменить или сбросить фильтры.
                                        </p>
                                    ) : (
                                        filteredProjects.map((project, index) => {
                                            const isAdded = addedProjects.some(addedProject => addedProject.id === project.id);
                                            return (
                                                <ProjectCard key={index} project={project} isAdded={isAdded}
                                                             handleAddToApplication={handleAddToApplication}/>
                                            );
                                        })
                                    )}
                                </div>
                            </div>
                        </>
                    ) :
                        archive ? (
                            <div className="bmm-text">
                                Здесь будут проекты БММ 2024 и позднее. Проекты Мастерских до 2023-го года можно найти на странице <Link to="/archive">Архив</Link>.
                            </div>
                        ) : (
                        // <div className="bmm-text mt-5">Полный список проектов БММ-2024 будет опубликован на этой странице к
                        //     01.05.2024.</div>
                        <div className="bmm-text">
                            Проекты текущей мастерской появятся на этой странице после модерации.
                            Проекты прошлых мастерских можно найти на странице <Link to="/archive">Архив</Link>.

                            <br/><br/>

                            <div className={"text-center"}>
                                <Link to={"/about"}>
                                    <Button view={"action"} size={"xl"}>
                                        О Мастерской
                                    </Button>
                                </Link>
                                </div>
                            {activeWorkshop?.customers_can_apply &&
                                <div className={"text-center"}>
                                <br/>
                                <Link to={"/account/project/create"}>
                                    <Button view={"action"} size={"xl"}>
                                        Подать проект на БММ 2025
                                    </Button>
                                </Link>
                            </div>
                            }

                        </div>
                    )
                )
            }
        </>
    );
}

export default ProjectsPage