import React, {useEffect, useState} from 'react';
import {Button, Modal, Switch} from "@gravity-ui/uikit";

import "./Modals.css"
import Profile from "../Profile";
import Chat from "../Chat/Chat";
import {
    ACTION_ENDPOINT,
    getJSONAcceptAuthConfig,
    USER_PERMISSIONS_ENDPOINT, WORKSHOP_INFO_ENDPOINT, WORKSHOP_PERMISSIONS,
    WORKSHOP_PERMISSIONS_DETAIL
} from "../../api/api";
import LoadingSpinner from "../LoadingSpinner";
import axios from "axios";

export function DeleteModal({type, name, onDelete, open, setOpen, additionalText = ""}) {
    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <div className="bmm-modal">
                <p>
                    Вы уверены, что хотите удалить {type} {name && `"${name}"`}{additionalText && ` ${additionalText}`}?
                </p>
                <div className="modal-buttons">
                    <Button onClick={onDelete} view={"flat-danger"}>
                        Да, удалить
                    </Button>
                    <Button onClick={() => setOpen(false)} view={"normal"}>
                        Нет, отменить
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export function UpdateModal({open, setOpen, children}) {
    return (
        <Modal className="modal-adaptive" open={open} onClose={() => setOpen(false)}>
            <div className="bmm-modal bmm-modal_form">
                {children}
            </div>
        </Modal>
    );
}

export function ConfirmModal({open, setOpen, text, onClick}) {
    return (
        <Modal className="modal-adaptive" open={open} onClose={() => setOpen(false)}>
            <div className="bmm-modal">
                <p>{text}</p>
                <div className="modal-buttons">
                    <Button onClick={onClick} view={"flat-danger"} width="max">
                        Да
                    </Button>
                    <Button onClick={() => setOpen(false)} view={"normal"} width="max">
                        Нет
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export function GraylistModal({user, projectId, open, setOpen, graylist, setGraylist, isLoading}) {
    return (
        <Modal className="modal-adaptive" open={open} onClose={() => setOpen(false)}>
            <div className="bmm-modal bmm-modal_chat">
                <Profile size={'m'} className={'mb-4'} profile={user}/>
                <Chat user={user} projectId={projectId} graylist={graylist} setGraylist={setGraylist}
                      isLoading={isLoading}/>
            </div>
        </Modal>
    );
}

const UserModal = ({user, open, setOpen}) => {
    const [userData, setUserData] = useState(null);
    const [actions, setActions] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (open && user.id) {
            setIsLoading(true);
            Promise.all([
                axios
                    .get(USER_PERMISSIONS_ENDPOINT(user.id), getJSONAcceptAuthConfig())
                    .then(response => {
                        setUserData(response.data);
                    })
                    .catch(error => {
                        console.error('Error fetching user data:', error);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    }),
                axios.get(ACTION_ENDPOINT, getJSONAcceptAuthConfig())
                    .then(response => {
                        setActions(response.data);
                    })
                    .catch(error => {
                        console.error('Error fetching actions:', error);
                    })
            ]).finally(() => {
                setIsLoading(false);
            });
        }
    }, [open, user]);

    function handleSwitchChange(actionId, switchState, permissionId) {
        if (!switchState) {
            // Если switch включён, удалить permission
            axios.delete(WORKSHOP_PERMISSIONS_DETAIL(permissionId), getJSONAcceptAuthConfig())
                .then(response => {
                    // console.log('Permission deleted:', response.data);
                    // Удалить permission из userData
                    setUserData(prevData => prevData.filter(permission => permission.id !== permissionId));
                })
                .catch(error => {
                    console.error('Error deleting permission:', error);
                });
        } else {
            // Если switch выключен, создать новый permission
            const payload = {
                user: user.id,
                action: actionId,
                workshop: 1 // FIXME Hardcoded workshop
            };

            axios.post(WORKSHOP_PERMISSIONS, payload, getJSONAcceptAuthConfig())
                .then(response => {
                    // console.log('Permission created:', response.data);
                    // Добавить новый permission в userData
                    setUserData(prevData => [...prevData, response.data]);
                })
                .catch(error => {
                    console.error('Error creating permission:', error);
                });
        }
    }

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <div className="bmm-modal bmm-modal_chat">
                <Profile size={'m'} className={'mb-4'} profile={user}/>
                {isLoading ?
                    <LoadingSpinner/>
                    :
                    <div>
                        {/* Здесь список всех полученных Action в виде Swtich с content равным name от Action, сhecked равным true если Action с соответствующим codename есть в userData */}
                        {actions && userData && actions.map(action => {
                            const permission = userData.find(permission => permission.action.codename === action.codename);
                            return (
                                <div key={action.id} className="switch-container">
                                <Switch
                                    key={action.id}
                                    content={action.name}
                                    checked={!!permission}
                                    onChange={(e) => handleSwitchChange(action.id, e.target.checked, permission ? permission.id : null)}
                                    disabled={false}
                                />
                            </div>
                            );
                        })}
                    </div>
                }
            </div>
        </Modal>
    );
};

export function WorkshopDeadlineModal ({workshop_id, open, setOpen}) {
    const [workshopData, setWorkshopData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [switches, setSwitches] = useState([
        {
            "name": "Показать кнопки подачи и редактирования проектов (заказчиками)",
            "field_name": "customers_can_apply"
        },
        {
            "name": "Показать кнопки подачи и редактирования заявок на участие (участниками)",
            "field_name": "workers_can_apply"
        },
        {
            "name": "Показать кнопки подтверждения участия (участниками)",
            "field_name": "workers_can_confirm"
        },
        {
            "name": "Показать кнопки подачи и редактирования тревел-грантов (участниками)",
            "field_name": "travellers_can_apply"
        }
    ]);

    useEffect(() => {
        if (open && workshop_id) {
            setIsLoading(true);
             axios
                .get(WORKSHOP_INFO_ENDPOINT(workshop_id), getJSONAcceptAuthConfig())
                .then(response => {
                    setWorkshopData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching workshop data:', error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [open, workshop_id]);

        function handleSwitchChange(field_name, switchState) {
            const body = JSON.stringify({[field_name]: switchState});
            axios
                .patch(WORKSHOP_INFO_ENDPOINT(workshop_id), body, getJSONAcceptAuthConfig())
                .then(response => {
                    setWorkshopData(response.data);
                })
                .catch(error => {
                    console.error('Error patching workshop data:', error);
                });

        }


    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <div className="bmm-modal">
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <h3>{workshopData?.name} {workshopData?.year}</h3>
                        <div>
                            {switches &&
                                switches.map((action) => (
                                    <Switch
                                        key={action.field_name}
                                        content={action.name}
                                        checked={!!workshopData[action.field_name]}
                                        onChange={(e) =>
                                            handleSwitchChange(
                                                action.field_name,
                                                e.target.checked
                                            )
                                        }
                                        disabled={false}
                                    />
                                ))}
                        </div>
                    </>
                )}
            </div>
        </Modal>
);
}

export default UserModal;