import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import axios from "axios";

import {Button, Icon, Label} from "@gravity-ui/uikit";
import {HeartFill} from "@gravity-ui/icons";

import {getJSONAcceptAuthConfig, getJSONAcceptConfig, PUBLIC_PROJECTS_DETAIL_ENDPOINT} from "../../api/api";
import UserCard from "../../components/userCard/UserCard";
import {ProjectKeywords, ProjectWorkshop} from "../../components/projectCard/utils";
import LoadingSpinner from "../../components/LoadingSpinner";

function ProjectPage({user}) {
    const {id} = useParams();
    const projectId = parseInt(id)

    const [projectData, setProjectData] = useState({});
    const [addedProjects, setAddedProjects] = useState(() => (JSON.parse(localStorage.getItem('addedProjects')) || []));

    let isAdded = addedProjects.some(addedProject => addedProject.id === projectId);

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'addedProjects') {
                const updatedAddedProjects = JSON.parse(event.newValue) || [];
                setAddedProjects(updatedAddedProjects);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    // useEffect(() => {
    //     const addedProjects = JSON.parse(localStorage.getItem('addedProjects')) || [];
    //     setAddedProjects(addedProjects);
    // }, []);

    const handleAddToApplication = (project) => {
        let updatedAddedProjects;
        const projectId = project.id;
        if (addedProjects.find(p => p.id === projectId)) {
            updatedAddedProjects = addedProjects.filter(p => p.id !== projectId);
        } else {
            updatedAddedProjects = [...addedProjects, project];
        }
        localStorage.setItem('addedProjects', JSON.stringify(updatedAddedProjects));
        setAddedProjects(updatedAddedProjects);
    };

    useEffect(() => {
        if (user && (user.is_ws_director || user.is_lws_director || user.is_ls_manager)) {
            axios
                .get(PUBLIC_PROJECTS_DETAIL_ENDPOINT(id), getJSONAcceptAuthConfig())
                .then(response => {
                    setProjectData({
                        ...response.data,
                    });
                })
                .catch(error => {
                    console.error('Error getting project data:', error);
                });
        } else {
            axios
                .get(PUBLIC_PROJECTS_DETAIL_ENDPOINT(id), getJSONAcceptConfig())
                .then(response => {
                    setProjectData({
                        ...response.data,
                    });
                })
                .catch(error => {
                    console.error('Error getting project data:', error);
                });
        }
    }, [user]);

    const extractDomain = (url) => {
        try {
            const {hostname} = new URL(url);
            return hostname;
        } catch (e) {
            return null;
        }
    };

    return (
        projectData.name
            ? <>
                <h1 className="page-title">{projectData.name}</h1>
                <div className="project-info">
                    Площадка: {projectData.venue?.name || '–'}
                    {projectData.workshop && <ProjectWorkshop workshop={projectData.workshop}/>}
                    <Label theme={"info"}>{projectData.venue?.city}</Label>
                    {projectData.is_for_schoolchildren && <Label theme={"warning"}>Для школьников</Label>}
                </div>
                <div className="project__keywords">
                    <ProjectKeywords keywords={projectData.keywords}/>
                </div>
                <div className="row d-flex flex-column flex-lg-row">
                    <div className="col-lg-8 mt-lg-0 mt-3">
                        <h2 className="bmm-title bmm-title__smaller">Описание проекта</h2>
                        <div className="bmm-text" dangerouslySetInnerHTML={{__html: projectData.description}}/>

                        <div>
                            <Button className="button-width-180 me-3 mt-3"
                                    view="action" size="xl"
                                    onClick={() => window.open(projectData.full_description_file, '_blank')}>
                                Полное описание
                            </Button>
                            {projectData.workshop?.is_active && (
    <Button className="me-3 mt-3" onClick={() => handleAddToApplication(projectData)} view={"normal"} size={"xl"}>
        <Icon data={HeartFill} size={18} className={isAdded ? 'heart-icon-active' : 'heart-icon-inactive'}/>
        {isAdded ? 'Добавлен в заявку' : 'Добавить в заявку'}
    </Button>
)}
                        </div>

                        <div>
                            {projectData.results_file &&
                                <Button className="button-width-180 me-3 mt-3"
                                        view="action" size="xl"
                                        onClick={() => window.open(projectData.results_file, '_blank')}>
                                    Итоговая заметка
                                </Button>
                            }
                            {projectData.results_url &&
                                <Button className="button-width-180 me-3 mt-3"
                                        view="action" size="xl"
                                        onClick={() => window.open(projectData.results_url, '_blank')}>
                                    Статья на {extractDomain(projectData.results_url)}
                                </Button>
                            }
                        </div>
                        {/*<Button className="button-width-180" view="action" size="xl">*/}
                        {/*    Итоги*/}
                        {/*</Button>*/}

                        <h2 className="bmm-title bmm-title__smaller">Требования к участникам</h2>
                        <ul className="bmm-text">
                            {projectData.requirements && projectData.requirements.split('\r\n').map((requirement, index, array) => (
                                <li key={index}>
                                    {requirement}
                                    {index !== array.length - 1 ? ';' : '.'}
                                </li>))}
                        </ul>
                        {projectData.test_file &&
                            <Button className="button-width-180 me-3 mt-3"
                                    view="action" size="xl"
                                    onClick={() => window.open(projectData.test_file, '_blank')}>
                                Тестовое задание
                            </Button>
                        }
                    </div>
                    <div className="col-lg-4">
                        <h2 className="bmm-title bmm-title__smaller">Команда проекта</h2>
                        <div className="bmm-card">
                            {projectData.customers?.length > 0 && projectData.customers.map((customer, index) => (
                                <UserCard
                                    key={index}
                                    name={`${customer.last_name} ${customer.first_name}${customer.middle_name ? ` ${customer.middle_name}` : ''}`}
                                    email={!projectData.managers?.some(obj => obj.id === customer.id) ? `Заказчик` : 'Заказчик и куратор'}
                                    imgUrl={customer.avatar}
                                    text={`${customer.title}`}
                                    size={"sp"}
                                    className={index > 0 ? "mt-4" : ""}
                                />))}
                            {projectData.managers?.length > 0 && projectData.managers.map((manager, index) => (
                                !projectData.customers?.some(obj => obj.id === manager.id) &&
                                <UserCard
                                    key={index}
                                    name={`${manager.last_name} ${manager.first_name}${manager.middle_name ? ` ${manager.middle_name}` : ''}`}
                                    email={`Куратор`}
                                    imgUrl={manager.avatar}
                                    text={`${manager.title}`}
                                    size={"sp"}
                                    className={"mt-4"}
                                />))}

                        </div>
                    </div>
                </div>
            </>
            : <LoadingSpinner/>
    );
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(ProjectPage);